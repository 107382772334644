import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'

const initialState = {
    projects: [],
    status: 'idle',
    error: null
}

export const fetchPosts = createAsyncThunk('projects/fetchProjects', async (portfolioName) => {

    if(typeof portfolioName !== 'undefined') {
        const portfolio_URL = process.env.REACT_APP_API_URL + 'portfolio-search'
        const response = await fetch(portfolio_URL, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({request_for: 'portfolio_search', search_string: portfolioName}),
            mode: 'cors'
        }).then(res => res.json())
        return response.data
    } else {
        const portfolio_URL = process.env.REACT_APP_API_URL + 'portfolios'
        const response = await fetch(portfolio_URL, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({request_for: 'portfolios'}),
            mode: 'cors'
        }).then(res => res.json())
        return response.data
    }
})

const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        reactionAdded(state, action) {
            const {projectId, reaction} = action.payload
            const existingProject = state.projects.find((project) => project.id === projectId)
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchPosts.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchPosts.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Add any fetched posts to the array
                state.projects = state.projects.concat(action.payload)
            })
            .addCase(fetchPosts.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})
export const {reactionAdded} = projectsSlice.actions

export default projectsSlice.reducer

export const selectAllProjects = state => state.projects.projects

export const selectProjectById = (state, projectId) => {
    const data =  state.projects.projects.filter(project => project.id === Number(projectId))
    if(typeof data[0] !== "undefined") {
        return data[0];
    } else {
        return [];
    }
}
