import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {nanoid} from 'nanoid';

function createMarkup(html) {
  return {__html: html};
}

const useFetch = (url, options) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setResponse(null);
    setError(null);
    const FetchData = async () => {
      try {
        const res = await fetch(url, options);
        const json = await res.json();
        setResponse(json);
      } catch (error) {
        setError(error);
      }
    };
    FetchData();
  }, [url]);
  return {response, error};
};

export const SingleProjectPage = ({match}) => {

  const {projectId} = match.params;

  const res = useFetch(process.env.REACT_APP_API_URL + 'portfolio-details', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({request_for: 'portfolio_details', portfolio_id: projectId}),
    mode: 'cors',
  });

  if (!res.response) {
    return (
        <div>
          <section id="main-banner-page" className="position-relative page-header section-nav-smooth parallax gallery-detail-header">
            <div className="overlay overlay-dark opacity-6 z-index-1"/>
            <div className="container">
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="page-titles whitecolor text-center padding_top padding_bottom">
                    <h2 className="font-xlight">&nbsp;</h2>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="gallery-detail" className="padding_top padding_bottom">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <h2 className="text-center"><img src={process.env.PUBLIC_URL + '/assets/images/loading.gif'} alt="Loading..."/></h2>
                </div>
              </div>
            </div>
          </section>
        </div>
    );
  }

  if (res.response.data.length === 0) {
    return (
        <div>
          <section id="main-banner-page" className="position-relative page-header section-nav-smooth parallax gallery-detail-header">
            <div className="overlay overlay-dark opacity-6 z-index-1"/>
            <div className="container">
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="page-titles whitecolor text-center padding_top padding_bottom">
                    <h2 className="font-xlight">&nbsp;</h2>
                    <h2 className="font-bold">404 - Project not found.</h2>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="gallery-detail" className="padding_top padding_bottom">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <h2 className="text-center">Project not found.</h2>
                </div>
              </div>
            </div>
          </section>
        </div>
    );

  } else {

    let project = res.response.data.portfolioDetails;
    let projectTechnologies = res.response.data.projectTechnologies;

    const imageUrl = JSON.parse(project.project_images);

    const renderedImages = imageUrl.map((image, index) => (
        <div className="cbp-item" key={index}>
          <a href={process.env.REACT_APP_BACKEND_URL + image} className="gallery-link" data-fancybox="screenshot-gallery" title="Zoom In">
            <img src={process.env.REACT_APP_BACKEND_URL + image} data-cbp-src={process.env.REACT_APP_BACKEND_URL + image} alt="Not found"/>
          </a>
        </div>
    ));

    const renderedTechStack = projectTechnologies.map(techs => (
        <li key={nanoid()}><Link to={`/${techs.name}`}>{techs.name}</Link></li>
    ));

    let urlElements = '';

    if (project.dev_site_url) {
      urlElements = '<p><b>Dev URL:</b> <a href="' + project.dev_site_url + '" target="_blank">' + project.dev_site_url + '</a></p>';
    }

    if (project.live_site_url) {
      urlElements += '<p><b>Live URL:</b> <a href="' + project.live_site_url + '" target="_blank">' + project.live_site_url + '</a></p>';
    }

    const script = document.createElement('script');
    script.src = process.env.PUBLIC_URL + '/assets/js/custom.js'; // whatever url you want here
    document.body.appendChild(script);

    return (
        <div>
          <section id="main-banner-page" className="position-relative page-header section-nav-smooth parallax gallery-detail-header">
            <div className="overlay overlay-dark opacity-6 z-index-1"/>
            <div className="container">
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="page-titles whitecolor text-center padding_top">
                    <h2 className="font-xlight">&nbsp;</h2>
                    <h2 className="font-bold">{project.project_name}</h2>
                    <h2 className="font-xlight">&nbsp;</h2>
                  </div>
                </div>
              </div>
              <div className="gradient-bg title-wrap bottom25">
                <div className="row">
                  <div className="col-lg-12 col-md-12 whitecolor">
                    <h3 className="float-left">Portfolio Details</h3>
                    <ul className="breadcrumb top10 bottom10 float-right">
                      <li className="breadcrumb-item"><Link to="/">Portfolio</Link></li>
                      <li className="breadcrumb-item">{project.project_name}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="gallery-detail" className="padding_top padding_bottom_half">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 top10">
                  <div className="news_item shadow">
                    <div className="news_desc text-center text-md-left">
                      <h1 className="text-capitalize font-normal darkcolor">{project.project_name}</h1>
                      <p className="bottom35">&nbsp;</p>
                      <div className="mb-5 project-description" dangerouslySetInnerHTML={createMarkup(project.project_description)}/>
                      <div className="mb-5 project-description" dangerouslySetInnerHTML={createMarkup(urlElements)}/>
                      <div className="profile-authors">
                        <h4 className="text-capitalize darkcolor mb-3">Tech Stack</h4>
                        <ul className="webtags">
                          {renderedTechStack}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 top10">
                  <div id="gallery-mosaic" className="news_item shadow gallery-padding cbp cbp-l-grid-mosaic-flat">
                    {renderedImages}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
    );
  }

};


