import React from 'react';
import {
  Switch,
  Route,
} from 'react-router-dom';
import routes from './Routes';
import NotFound from './features/NotFound';

export const MainContent = () => {

  return (
      <Switch>
        {routes.map(({path, Component}, key) => (
            <Route exact path={path} key={key} component={Component}/>
        ))}
        <Route component={NotFound}/>
      </Switch>
  );

};
