import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

function getImageUrl(project) {
  const imageUrl = JSON.parse(project.project_images);
  if (typeof imageUrl !== 'undefined' && imageUrl.length > 0) {
    return imageUrl[0];
  } else {
    return '';
  }
}

function getTechnologyClasses(technologies) {
  if (technologies) {
    const project_technologies = JSON.parse(technologies);
    return 'cat' + project_technologies.join(' cat');
  } else {
    return '';
  }
}

const useFetch = (url, options) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setResponse(null);
    setError(null);
    const FetchData = async () => {
      try {
        const res = await fetch(url, options);
        const json = await res.json();
        setResponse(json);
      } catch (error) {
        setError(error);
      }
    };
    FetchData();
  }, [url]);
  return {response, error};
};

export const ProjectsList = (param) => {

  const portfolioName = param.portfolioName.portfolioName;

  let API_URL = process.env.REACT_APP_API_URL + 'portfolios';
  let API_OPTIONS = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({request_for: 'portfolios'}),
    mode: 'cors',
  };

  if (typeof portfolioName !== 'undefined') {
    API_URL = process.env.REACT_APP_API_URL + 'portfolio-search';
    API_OPTIONS = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({request_for: 'portfolio_search', search_string: portfolioName}),
      mode: 'cors',
    };
  }

  let res = useFetch(API_URL, API_OPTIONS);
  if (res.response === null) {
    return (
        <div className="col-lg-12">
          <h2 className="text-center padding_bottom_half"><img src={process.env.PUBLIC_URL + '/assets/images/loading.gif'} alt="Loading..."/></h2>
        </div>
    );
  } else {

    let projects = res.response.data.projects;

    if (!projects) {
      return (
          <div className="col-lg-12">
            <h2 className="text-center padding_bottom_half">No projects found.</h2>
          </div>
      );
    } else {

      let projectTechnologies = res.response.data.projectTechnologies;

      const script = document.createElement('script');
      script.src = process.env.PUBLIC_URL + '/assets/js/custom.js'; // whatever url you want here
      document.body.appendChild(script);

      const renderedProjects = projects.map((project, index) => (
          <div className={'cbp-item ' + getTechnologyClasses(project.category_id)} key={index}>
            <img src={process.env.REACT_APP_BACKEND_URL + getImageUrl(project)} alt={project.project_name}/>
            <div className="gallery-hvr whitecolor">
              <div className="center-box">
                <a href={process.env.REACT_APP_BACKEND_URL + getImageUrl(project)} className="opens" data-fancybox="projects-gallery" data-caption={`<a href="${`/portfolio-details/${project.id}`}">${project.project_name}</a>`} title="Zoom In"><i className="fa fa-search-plus"/></a>
                <Link to={`/portfolio-details/${project.id}`} className="opens" title="View Details"><i className="fas fa-link"/></Link>
                <h4 className="w-100">{project.project_name}</h4>
              </div>
            </div>
            {/*<a href={process.env.REACT_APP_BACKEND_URL + getImageUrl(project)} className="gallery-link" data-fancybox="gallery" title="Zoom In"/>*/}
          </div>
      ));

      const renderedTechnologies = projectTechnologies.map((tech, index) => {
        return (
            <div data-filter={`.cat${tech.id}`} className="cbp-filter-item" key={index}>
              <span>{tech.name}</span>
            </div>
        );
      });

      return (
          <div>
            <div className="col-lg-12">
              <div id="mosaic-filter" className="cbp-l-filters bottom50 wow fadeIn text-center" data-wow-delay="350ms">
                <div data-filter="*" className="cbp-filter-item cbp-filter-item-active">
                  <span>All</span>
                </div>
                {renderedTechnologies}
              </div>
            </div>
            <div className="col-lg-12 padding_bottom">
              <div id="grid-mosaic" className="cbp cbp-l-grid-mosaic-flat">
                {renderedProjects}
              </div>
            </div>
          </div>
      );
    }
  }

};
