import React, {Component} from 'react';
import {ProjectsList} from '../features/projects/ProjectsList';

class Portfolio extends Component {

  constructor(props) {
    super(props);
  }

  render() {

    let params = null;
    if (typeof this.props.match !== 'undefined') {
      params = this.props.match.params;
    }

    return (
        <div>
          <section id="main-banner-page" className="position-relative page-header gallery-header parallax section-nav-smooth padding_top">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="page-titles whitecolor text-center padding_half">
                    <h2 className="font-xlight">Welcome</h2>
                    <h2 className="font-bold">To Our Portfolio Work</h2>
                    <h2 className="font-xlight">Let's Build</h2>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="gallery" className="bglight position-relative padding_top_half">
            <div className="container">
              <div className="row">
                  <ProjectsList portfolioName={params}/>
              </div>
            </div>
          </section>
        </div>
    );
  }
}

export default Portfolio;
