import React from 'react';

export const Footer = () => {
  return (
      <div className="bgdark copyright">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center wow fadeIn animated" data-wow-delay="300ms">
              <p className="m-0 py-3 text-white">© <span id="year1"/> Codesigma Technology Services</p>
            </div>
          </div>
        </div>
      </div>
  );
};


