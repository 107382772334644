import React from 'react';
import {Link} from 'react-router-dom';

const NotFound = () => (
    <div>
      <section id="main-banner-page" className="position-relative page-header gallery-header parallax section-nav-smooth">
        <div className="overlay overlay-dark opacity-2 z-index-1"/>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div className="page-titles whitecolor text-center padding_top padding_bottom">
                <h1 className="font-light font-404-heading padding_top">404</h1>
                <h3 className="font-light">That Page Couldn't Be Found</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="error" className="padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="error wow bounceIn" data-wow-delay="300ms">
                <h1>404</h1>
                <h2>404</h2>
              </div>
              <p className="heading_space">We are sorry, the page you want isn’t here anymore. May be one of the links below can help !</p>
              <Link to="/" className="button gradient-btn wow fadeIn mb-3 mb-sm-0" data-wow-delay="400ms">back to home</Link>
            </div>
          </div>
        </div>
      </section>
    </div>

);

export default NotFound;