import { configureStore } from '@reduxjs/toolkit'
import projectsReducer from '../features/projects/projectsSlice'
import technologiesReducer from "../features/projects/technologiesSlice";
import portfolioReducer from "../features/projects/portfolioSlice";

export default configureStore({
    reducer: {
        projects: projectsReducer,
        technologies: technologiesReducer,
        portfolio: portfolioReducer
    },
})
