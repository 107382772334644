import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'

const initialState = {
    technologies: [],
    status: 'idle',
    error: null
}

export const fetchTechnologies = createAsyncThunk('technologies/fetchTechnologies', async () => {
    const portfolio_URL = process.env.REACT_APP_API_URL + 'project-technologies'
    const response = await fetch(portfolio_URL, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({request_for: 'project_technologies'}),
        mode: 'cors'
    }).then(res => res.json())
    return response.data
})

const technologiesSlice = createSlice({
    name: 'technologies',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchTechnologies.pending, (state, action) => {
            state.status = 'loading'
        }).addCase(fetchTechnologies.fulfilled, (state, action) => {
            state.status = 'succeeded'
            // Add any fetched posts to the array
            state.technologies = state.technologies.concat(action.payload)
        }).addCase(fetchTechnologies.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
    }
})

export default technologiesSlice.reducer

export const selectAllTechnologies = state => state.technologies.technologies

export const selectTechnologyById = (state, technologyId) => state.technologies.find(technology => technology.id === technologyId)
