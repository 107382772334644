import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

const initialState = {
  portfolio: [],
  status: 'idle',
  error: null,
};

export const fetchPortfolio = createAsyncThunk('portfolio/fetchPortfolio', async (portfolio_id) => {
  const portfolio_URL = process.env.REACT_APP_API_URL + 'portfolio-details';
  const response = await fetch(portfolio_URL, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({request_for: 'portfolio_details', portfolio_id: portfolio_id}),
    mode: 'cors',
  }).then(res => res.json());
  return response.data;
});

const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchPortfolio.pending, (state, action) => {
      state.status = 'loading';
    }).addCase(fetchPortfolio.fulfilled, (state, action) => {
      state.status = 'succeeded';
      // Add any fetched posts to the array
      state.portfolio = state.portfolio.concat(action.payload);
    }).addCase(fetchPortfolio.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    });
  },
});
export const {reactionAdded} = portfolioSlice.actions;

export default portfolioSlice.reducer;

export const selectPortfolioById = state => state.portfolio.portfolio;
