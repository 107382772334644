import React from "react";
import {Link} from "react-router-dom";

export const Header = () => {
    return (
        <div>
            <div className="loader">
                <div className="loader-inner">
                    <div className="cssload-loader"/>
                </div>
            </div>
            <header className="site-header" id="header">
                <nav className="navbar navbar-expand-lg transparent-bg bg-transparent darkcolor mt-sm-4 mt-2">
                    <div className="container bg-white border-radius-100 position-relative text-center display-block">
                        <Link to="/" className="navbar-brand pl-2">
                            <img src={process.env.PUBLIC_URL + '/assets/images/logo.png'} alt="logo" className="logo-default" />
                            <img src={process.env.PUBLIC_URL + '/assets/images/logo.png'} alt="logo" className="logo-scrolled" />
                        </Link>
                    </div>
                </nav>
            </header>
        </div>
    )
}
