import React from "react";
import { Header } from './Header';
import { Footer } from './Footer';
import {MainContent} from '../MainContent';

export const Layout = () => {
    return (
        <div>
            <Header />
            <MainContent />
            <Footer />
        </div>
    )
}
